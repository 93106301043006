import React from 'react'
import styled from 'styled-components';
import { Flex } from './Flex';
import { useStaticQuery } from 'gatsby';
import { Paragraph, Heading, AParagraph, AHeading } from './Paragraph';
import { graphql } from 'gatsby'
import { Hover } from './Hover';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useSpring, animated, useChain } from 'react-spring';
import { useWindowSize } from '../hooks/useWindowSize';
import { useState } from 'react';
import { AreaPortal } from './AreaPortal';
import { ACleanButton } from './CleanButton';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { above } from '../styles/breakpoints';
import { LayoutPadding } from '../layout/LayoutPadding';
import { LanguageContext } from '../layout/Layout';
import { useContext } from 'react';
import { fontWeight, fontSize } from '../styles/fonts';

const GridList = styled.ul`
    position: relative;
    display: flex;
    display: grid;
    flex-wrap: wrap;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    padding: 0;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    ${above.med`
        max-width: 1800px;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
    z-index: 1;

`

const AGridList = animated(GridList);

const parts = [
    <polyline points="78.5 28.59 37.21 75.5 23.5 63.65 57.96 24.5" fillRule="evenodd"></polyline>,
    <path d="M94,34.27a69.09,69.09,0,0,1-9.24,18.3c-17,24.27-46.95,29.95-69.61,13.81A51.58,51.58,0,0,1,8,60.24L21.18,45.75a32.76,32.76,0,0,0,5.2,4.6c14.05,10,31.73,7.62,43-8.53,4.46-6.37,7.65-13.48,7.33-19.31"></path>,
    <path d="M57.64,39.55,52.87,55.17l19.33,6L66.93,78.4,29.8,67l12.9-42.3c5.81-3.45,13-5,20.59-5.06" fillRule="evenodd"></path>,
    <path d="M28.6,23.4c27.6,1.7,47.5,24.4,45.8,51.3l-20.9-1.3c1.1-16.5-9.7-30-26.1-31.1" />,
]

const AreaItem = styled(Flex)`
    svg.areaItem {
        width: 70%;
        transform: translateZ(50px);
        stroke: none;
    }
    svg.close {
        width: 2em;
        height: 2em;
    }
    ${Paragraph} {
        transform: translateZ(45px);
    }
    
`

const HiddenAreaText = animated(styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
    z-index: 9000;
    padding: 2em;
    padding-top: 0;
    ${Paragraph} {
        opacity: 0;
    }
`)
const AreaTitle = styled(Paragraph)`
    margin: 0.5em 0 0.7em 0;
    font-size: ${fontSize('card-title')};
    font-weight: ${fontWeight('regular')};
`

const AreaCardButton = styled(Flex)`
    padding: 1.5em;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
    
`

const Button = styled.button`
    cursor: pointer;
    border: none;
    background: none;           
    padding: 0;
    margin: 0;
    `

const Area = styled(({ className, part = 0, title, text, setParentStyle, resetHeight }) => {

    const card = useRef()
    const hiddenText = useRef()

    const [isOpen, setIsOpen] = useState(false)
    const lastIsOpen = useRef(false);
    const [cardWidth, setCardWidth] = useState(0)
    const [cardHeight, setCardHeight] = useState(0)
    const [cardLeft, setCardLeft] = useState(0)
    const [cardTop, setCardTop] = useState(0)
    const [parentWidth, setParentWidth] = useState(0)
    const [parentHeight, setParentHeight] = useState(0)
    const [parentLeft, setParentLeft] = useState(0)
    const [parentTop, setParentTop] = useState(0)
    const [hiddenTextHeight, setHiddenTextHeight] = useState(0)

    const { innerWidth } = useWindowSize()

    useEffect(() => {

        const { left, top, height, width } = card.current.parentNode.getBoundingClientRect()

        setParentLeft(left)
        setParentTop(top)
        setParentHeight(height)
        setParentWidth(width)

    }, [innerWidth])
    useEffect(() => {

        const { left, top, height, width } = card.current.getBoundingClientRect()

        setCardLeft(card.current.offsetLeft)
        setCardTop(card.current.offsetTop)
        setCardHeight(height - (18 * 2))
        setCardWidth(width - (18 * 2))

    }, [innerWidth])
    useEffect(() => {

        const { left, top, height, width } = hiddenText.current.getBoundingClientRect()
        setHiddenTextHeight(height);

    }, [parentWidth])

    const springRef = useRef();
    const springHRef = useRef();
    const springPRef = useRef();
    const springBRef = useRef();

    useEffect(() => {
        if(!lastIsOpen.current && isOpen) {
            setParentStyle({
                height: (hiddenTextHeight) + "px"
            })
        }
        if(lastIsOpen.current && !isOpen) {
            resetHeight();
        }
        lastIsOpen.current = isOpen;
    }, [isOpen])

    const propsButton = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        reverse: !isOpen,
        ref: springBRef,
    });
    const propsHeader = useSpring({
        from: {
            opacity: 0,
            transform: 'translateY(40px)'
        },
        to: {
            opacity: 1,
            transform: 'translateY(0px)'
        },
        reverse: !isOpen,
        ref: springHRef,
    });
    const propsText = useSpring({
        from: {
            opacity: 0,
            transform: 'translateY(40px)',
            marginTop: 0,
        },
        to: {
            opacity: 1,
            transform: 'translateY(0px)',
            marginTop: 0,
        },
        reverse: !isOpen,
        ref: springPRef,
    });
    const rotationRef = useRef([0, 0, 0]);
    const props = useSpring({
        from: {
            top: cardTop + 'px',
            left: cardLeft + 'px',
            height: cardHeight + 'px',
            width: cardWidth + 'px',
            opacity: 0,
            pointerEvents: 'none',  
            overflow: 'hidden',
            transform: `translateZ(1000px)`,
        },
        to: {
            top: 0 + 'px',
            left: 0 + 'px',
            height: hiddenTextHeight + 'px',
            width: parentWidth + 'px',
            opacity: 1,
            pointerEvents: 'auto',
            transform: `translateZ(1000px)`
        },
        reverse: !isOpen,
        ref: springRef
    })

    const openArea = () => {
        setIsOpen(true)
    }

    useOnClickOutside(card, () => setIsOpen(false));

    useChain(isOpen ? [springRef, springHRef, springPRef, springBRef] : [springBRef, springPRef, springHRef, springRef],
        isOpen ? [0, 0.5, 0.5, 0.5] : [0, 0, 0, 0.5]);

        
  const lang = useContext(LanguageContext);

    return (
        <Fragment>
            <AreaItem as="li" className={className} ref={card}>
                <Button as="button" onClick={openArea}>
                    <Hover rotationRef={rotationRef}>
                        <AreaCardButton column align="center">
                            <svg className="areaItem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102">
                                {parts[part]}
                            </svg>
                            <AreaTitle as="h3">{title[lang]}</AreaTitle>
                        </AreaCardButton>
                    </Hover>
                </Button>
                <HiddenAreaText style={props}>
                    <Flex justify="space-between" align="center">
                        <AHeading as="h3" size="ingress" weight="bold" style={propsHeader}>{title[lang]}</AHeading>
                        <ACleanButton onClick={() => setIsOpen(false)} style={propsButton}>
                            <svg className="close invert" viewBox="0 0 512 512">
                                <g>
                                    <line x1="8" y1="8" x2="504" y2="504" strokeWidth="35" strokeMiterlimit="10"></line>
                                    <line x1="8" y1="504" x2="504" y2="8" strokeWidth="35" strokeMiterlimit="10"></line>
                                </g>
                            </svg>
                        </ACleanButton>
                    </Flex>
                    <AParagraph size="label" weight="medium" style={propsText}>{text[lang]}</AParagraph>
                </HiddenAreaText>
                <AreaPortal>
                    <HiddenAreaText ref={hiddenText} style={{ width: parentWidth + `px` }}>
                        <Flex justify="space-between" align="center">
                            <AHeading as="h3" size="ingress" weight="bold">{title[lang]}</AHeading>
                            <ACleanButton>
                                <svg className="close invert" viewBox="0 0 512 512">
                                    <g>
                                        <line x1="8" y1="8" x2="504" y2="504" strokeWidth="35" strokeMiterlimit="10"></line>
                                        <line x1="8" y1="504" x2="504" y2="8" strokeWidth="35" strokeMiterlimit="10"></line>
                                    </g>
                                </svg>
                            </ACleanButton>
                        </Flex>
                        <Paragraph size="label" weight="medium" style={{ marginTop: 0 }}>{text[lang]}</Paragraph>
                    </HiddenAreaText>
                </AreaPortal>
            </AreaItem>
        </Fragment >
    )
})`
justify-self: end;
&:nth-of-type(2n) {
    justify-self: start;
}
${above.large`
justify-self: center;
&:nth-of-type(2n) {
    justify-self: center;
}
`}
    color: ${({ theme }) => theme.secondary};
    svg {
        fill: ${({ theme }) => theme.secondary};
    }
    overflow: visible;
`

export const query = graphql`
  query AreasQuery {
    allSanityArea {
        edges {
          node {
            _id
            title {
              nb
              en
            }
            body {
              nb
              en
            }
          }
        }
      }
  }
`

const AreasHeading = styled(Paragraph)`
    width: 100%;
    text-align: center;
    grid-column: span 2;
    margin-top: 0;
    margin-bottom: 2em;
`


export const Areas = styled(({ className }) => {

    const result = useStaticQuery(query);
    const areas = result && result.allSanityArea && result.allSanityArea.edges.map(edge => edge.node);

    const ref = useRef();
    const [initialHeight, setInitialHeight] = useState(0);
    const { innerWidth } = useWindowSize();

    
        const [props, set, stop] = useSpring(() => ({
                height:  initialHeight + "px"
        }))
    useEffect(() => {
    
        ref.current.style.height = "auto";
        const { height } = ref.current.getBoundingClientRect();

        setInitialHeight(height);
        set({
            height: height + "px"
        })
        
    }, [innerWidth]);
    
    const resetHeight = () => {
        set({
            height: initialHeight + "px",
            delay: 500
        })
    }

    const lang = useContext(LanguageContext);

    const title = lang === `nb` ? `OMRÅDER` : `AREAS`;
    
    return (
        <Wrapper>

        <Flex column className={className} >
            <AreasHeading as="h2" size="front-heading" weight="medium">{title}</AreasHeading>
            <AGridList style={props} ref={ref}>
                {areas.map((area, index) => <Area key={area._id} part={index} title={area.title} text={area.body} setParentStyle={set} resetHeight={resetHeight} />)}
            </AGridList>
        </Flex>
        </Wrapper>
    )
})`
overflow: visible;
margin-bottom: 10%;

`

const Wrapper = styled.div`
width: 74vw;
margin-left: auto;
margin-right: auto;
overflow: visible;
margin-bottom: 10%;
`